.Footer {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 9999;
    font-size: 1rem;
    font-weight: 450;
    display: flex;
    flex-grow: 0;
    gap: 0.5rem;
    box-sizing: border-box;
    justify-content: flex-end;
}
.Footer a {
    text-decoration: none;
    display: block;
    padding: 0.5rem 1rem;
    color: rgb(63, 0, 255);
    background-color: rgb(250, 248, 248);
    border: 1px solid rgb(63, 0, 255);
    border-radius: 2rem;
}
.Footer a:hover {
    color: white;
    background-color: rgb(63, 0, 255);
}

@media screen and (max-width: 1200px) {
    .Footer {
        font-weight: 400;
    }
}

@media screen and (max-width: 900px) {
    .Footer {
        bottom: 1rem;
        right: 0.875rem;
        font-size: 0.875rem;
    }
    .Footer a {
        padding: 0.5rem 0.875rem;
        font-weight: 400;
    }
}
@media screen and (max-width: 600px) {
    .Footer {
        bottom: 1rem;
        right: 1rem;
        font-size: 0.875rem;
    }
    .Footer a {
        border-width: 1px;
        font-weight: 400;
    }
}
.Wall {
    /*display: grid;*/
    /*grid-template-columns: repeat(1, 50vw);*/
    display: none;
}

.WallImage {
    width: 50vw;
    height: 50vh;
    object-fit: cover; /* prevents images from distorting, skewing */
    background-color: #faf8f8;
    display: none; /* block */
}

.WallImageWrapper {
}

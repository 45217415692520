.PageWrapper {
    flex-grow: 1;
    width: 100vw;
    overflow: scroll;
}
.Home .PageWrapper {
    flex-grow: 1;
    width: 50vw;
    overflow: scroll;
}
.Page {
    font-size: 2rem;
    font-weight: 400;
    line-height: 3rem;
    padding: 1rem 25% 2rem 5.5rem;
    max-width: 1440px; 
}
.Home .Page {
    font-size: 1.5rem;
    line-height: 2.25rem;
    padding: 1rem 2rem 2rem 2rem;
}

.Page h1 {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.6rem;
    margin: 0;
    padding: 0 0;
}
.Page h2 {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.6rem;
    margin: 0;
    padding: 0 0 4rem;
    font-style: italic;
}
.Page h3 {
    font-weight: 400;
    font-size: 2rem;
    margin: 0;
    padding: 0 0 0.25rem;
    font-style: italic;
}
.Page h5 {
    font-size: 1rem;
    font-weight: 450;
    margin: 0; 
    padding: 0 0;
}
.Page h6 {
    font-size: 1rem;
    font-weight: 450;
    margin: 0;
    padding: 0 0;
}
.Page p {
    margin: 0;
    padding: 0 0 3rem;
}
.Page img {
    max-width: 100%;
    border-radius: 4px;
}
.Page div.Caption {
    font-style: italic;
    font-size: 1rem;
    line-height: normal;
    font-weight: 450;
}
.Page div.Caption::before {
    content: "↑ ";
}
.Page a {
    color: rgb(63, 0, 255);
    text-decoration-thickness: 1px;
}
.Page a:active {
    background-color: #FCE7EA;
}
.Page a:visited {
    color: rgb(63, 0, 255);
}
.Page strong {
    font-weight: 500;
}
.Page em {
    font-weight: 400;
    font-style: italic;
}
.Page em > strong {
    background-color: #FCE7EA;
    background-blend-mode: darken;
    padding: 0.125rem 0.25rem;
    font-style: normal;
    font-weight: 400;
}
.Page ul {
    margin: 0;
    padding: 0 0 3rem;
    list-style-type: "– ";
}
.Page ol {
    margin: 0;
    padding: 0 0 3rem;
}
.Page li {
    list-style-position: outside;
    line-height: 3rem;
}
.Page code {
    font-family: 'IBM Plex Mono', monospace;
    font-size:  1.5rem;
    background-color: rgb(242, 240, 240);
    padding: 0 4px;
    border-radius: 2px;
}
.Page hr {
    margin: 1rem 0 0.5rem 0;
    border-top: 1px solid rgb(63, 0, 255);
    width: 25%;

}
.Page hr + ul {
    list-style-type: "↗ ";
    margin: 0;
    padding: 0;
}
.Page hr + ul li {
    list-style-position: outside;
    font-size: 1rem;
    line-height: 1.5rem;
}
.Page hr + ul li a {
    text-decoration-thickness: 1px;
    font-weight: 450;
}
#home.PageWrapper .Page ul {
    list-style-type: "→ ";
}
#projects.PageWrapper .Page p {
    font-size: 1rem;
    padding: 0 0 1.5rem;
    line-height: 1.5rem;
}

@media screen and (max-width: 1200px) {
    .Page {
        font-size: 1.5rem;
        line-height: 2.25rem;
        padding: 0 20% 2rem 5.5rem;
    }
    .Page h1 {
        font-size: 1.5rem;
        line-height: 2rem;
    }
    .Page h2 {
        font-size: 1.5rem;
        line-height: 2rem;
        padding-bottom: 2.5rem;
    }
    .Page h3 {
        font-size: 1.5rem;
    }
    .Page h5 {
        font-size: 1rem;
    }
    .Page h6 {
        font-size: 1rem;
    }
    .Page p, #projects.PageWrapper .Page p {
        font-size: 1.5rem;
        line-height: 2.25rem;
        padding-bottom: 2rem;
    }
    .Page ul {
        padding-bottom: 2rem;
    }
    .Page li, .Page ul {
        font-size: 1.5rem;
        line-height: 2.25rem;
    }
    .Page img {
        height: auto;
    }
    .Page div.Caption {
        font-size: 1rem;
    }
    .Page hr + ul li {
        list-style-position: inside;
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .Page hr + ul li a {
        font-weight: 400;
    }
    .Page code {
        font-size:  0.75rem;
    }
}
@media screen and (max-width: 900px) {
    .Page {
        font-size: 1rem;
        line-height: 1.5rem;
        padding: 0 20% 1rem 1rem;
    }
    .Page h1 {
        font-size: 1rem;
        line-height: 1.25rem;
    }
    .Page h2 {
        font-size: 1rem;
        line-height: 1.5rem;
        padding-bottom: 1.5rem;
    }
    .Page h3 {
        font-size: 1rem;
    }
    .Page h5 {
        font-size: 0.875rem;
        font-weight: 400;
    }
    .Page h6 {
        font-size: 0.75rem;
    }
    .Page p, #projects.PageWrapper .Page p {
        font-size: 1rem;
        line-height: 1.5em;
        padding-bottom: 1.5rem;
    }
    .Page div.Caption {
        font-size: 0.875rem;
        font-weight: 400;
    }
    .Page ul {
        padding-bottom: 1.5rem;
    }
    .Page ol {
        padding-bottom: 1.5rem;
    }
    .Page li {
        list-style-position: inside;
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .Page hr + ul li a {
        text-decoration-thickness: 1px;
        font-weight: 400;
    }
    .Page hr + ul li {
        list-style-position: inside;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
}

@media screen and (max-width: 600px) {
    .Page {
        font-size: 1rem;
        line-height: 1.5rem;
        padding: 0 1.5rem 4.5rem 1rem;
    }
    .Page h1 {
        font-size: 1rem;
        line-height: 1.25rem;
    }
    .Page h2 {
        font-size: 1rem;
        line-height: 1.5rem;
        padding-bottom: 1.5rem;
    }
    .Page h3 {
        font-size: 1rem;
    }
    .Page h5 {
        font-size: 0.875rem;
        font-weight: 400;
    }
    .Page h6 {
        font-size: 0.75rem;
    }
    #projects.PageWrapper .Page p {
        font-size: 0.875rem;
        line-height: 1.25em;
        padding-bottom: 1.5rem;
    }
    .Page div.Caption {
        font-size: 0.75rem;
        font-weight: 400;
    }
    #home .Page ul {
        margin: 0;
        padding: 0 0;
        list-style-type:none;
    }
    .Page ol {
        padding-bottom: 1.5rem;
    }
    #home .Page li {
        list-style-position: inside;
        font-size: 1rem;
    }
    #home .Page li a {
        padding: 0.5rem 0;
        display: inline-block;
    }
    .Page img {
        width: 100%;
        height: auto;
    }
    .Page hr {
        border-top: 1px solid rgb(63, 0, 255);
    }
    .Page hr + ul li {
        list-style-position: inside;
        font-size: 0.875rem;
        line-height:200%;
    }
   .Page hr + ul li a {
        font-weight: 400;
        display: inline-block;
    }
    .Page code {
        font-size: 0.875rem;
    }
}

@font-face {
    font-family: "IBM Plex Sans";
    src: url("fonts/IBMPlexSans-Regular.woff2") format('woff2');
    font-weight: 400; 
    font-style: normal;
}
@font-face {
  font-family: "IBM Plex Sans";
  src: url("fonts/IBMPlexSans-Text.woff2") format('woff2');
  font-weight: 450; 
  font-style: normal;
}
@font-face {
  font-family: "IBM Plex Sans";
  src: url("fonts/IBMPlexSans-Italic.woff2") format('woff2');
  font-weight: 400; 
  font-style: italic;
}
@font-face {
  font-family: "IBM Plex Sans";
  src: url("fonts/IBMPlexSans-Medium.woff2") format('woff2');
  font-weight: 500; 
  font-style: normal;
}
@font-face {
  font-family: "IBM Plex Mono";
  src: url("fonts/IBMPlexMono-Regular.woff2") format('woff2');
  font-weight: 400; 
  font-style: normal;
}

html, body {
    font-family: 'IBM Plex Sans', -apple-system, 'Segoe UI', sans-serif;
    height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(250, 248, 248);
    color: rgb(63 0 255);
    margin: 0;
    padding: 0;
}


.Header {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    font-size: 1rem;
    font-weight: 450;
    width: 100vw;
    box-sizing: border-box;
}
.Header a {
    text-decoration: none;
    color: rgb(63, 0, 255);
}
.Header a:hover {
    text-decoration: none;
}
.Header a:visited {
    color: rgb(63, 0, 255);
}
.Header .Back {
    width: 2rem;
    display: block;
    font-size: 2rem;
    font-weight: 400;
}

@media screen and (max-width: 1200px) {
    .Header {
        font-weight: 400;
    }
}

@media screen and (max-width: 900px) {
    .Header {
        font-size: 0.875rem;
        font-weight: 400;
        padding: 0.75rem 2rem 1rem 1rem;
        gap: 1rem;
    }
    .Header .Back {
        font-size: 1.5rem;
        gap: 0.5rem;
        width:fit-content;
    }
}
@media screen and (max-width: 600px) {
    .Header {
        font-size: 0.875rem;
        font-weight: 400;
        padding: 0.75rem 1.5rem 0.75rem 1rem;
        gap: 0.5rem;
    }
    .Header .Back {
        width: fit-content;
        font-size: 1.5rem;
    }
}